html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.app {
  text-align: center;
}

h1, h2, h3, h4, h5, h6 {
  // color: #363636;
  margin: 0;
  // text-transform: capitalize;
}

header {
  padding: 12px 8px;
  background-color: #282c34;
  color: white;
}

.grocery-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  min-height: 70px;  
  margin: 0;
  
  &--empty {
    color: #4DA1A9;
  }
}

.grocery-item {
  padding: 12px 16px; 
  margin: 8px;
  color: white;
  background-color: #4DA1A9;
  border-radius: 0.25rem;
  transition: background-color 200ms ease-in-out;
  position: relative;

  &:hover {
    cursor: pointer;
    background-color: #12596b;
  }
  &--disabled {
    background-color: #ccc;
    &:hover {
      background-color: #ccc;
    }
  }
  &--selected {
    background-color: #30CA30;
    &:hover {
      background-color: #D64933;
    }
  }
  &--edit {
    padding: 12px 28px 12px 4px;
    // background-color: #D64933 !important;
    // &:hover {
    //   background-color: #df614e;
    // }
  }

  .grocery-item__remove {
    position: absolute;
    width: 24px;
    font-size: 0.75rem;
    background-color: #D64933;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    right: 0;
    height: 100%;
    top: 0%;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

    &:hover {
      background-color: #df614e;
    }
  }
}

.warning-text {
  margin: 4px;
}

.btn {
  padding: 12px; 
  margin: 0 8px;
  color: white;
  background-color: rgb(0, 136, 0);
  border-radius: 0.25rem;
  border: none;
  margin-top: 8px;

  &:hover {
    cursor: pointer;
    background-color: #36db36;
  }
}

footer {
  height: 40px;
  background-color: #282c34;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.editmode {
  display: flex;
  align-items: center;
  span {
    margin-right: 4px;
  }
  .editmode__switch {
    transform: scale(0.75);
  }
}

.buy-section, .allgroceries {
  padding: 0;
  // background-color: rgb(160, 204, 173);
  .buy-section__area {
    padding: 16px 16px 8px 16px;
  }
  .buy-section__area:nth-of-type(2n) {
    background-color: rgba(0,0,0,.05)
  }
}

.edit-area {
  padding: 16px;
  background-color: #282c34;
  color: white; 
  display: flex;
  flex-direction: column;
  align-items: center;


  h5 {
    margin-bottom: 8px;
  }
  
  input {
    margin-bottom: 8px;
    width: 80%;
    max-width: 200px;
    height: 40px;
  }

  .edit-area__dropdown {
    width: 200px;
    height: 40px;
    margin: 0 auto;
    color: #282c34;
  }
}